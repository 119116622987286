import {
  FormEventHandler,
  useEffect
} from 'react'
import Checkbox from '@/Components/Checkbox'
import GuestLayout from '@/Layouts/GuestLayout'
import InputError from '@/Components/InputError'
import InputLabel from '@/Components/InputLabel'
import PrimaryButton from '@/Components/PrimaryButton'
import TextInput from '@/Components/TextInput'
import {
  Head,
  Link,
  useForm
} from '@inertiajs/react'
import {
  Divider,
  Flex
} from "@mantine/core";

export default function Login({status, canResetPassword}: { status?: string, canResetPassword: boolean }) {
  const {data, setData, post, processing, errors, reset} = useForm({
    email: '',
    password: '',
    remember: false,
  })

  useEffect(() => {
    return () => {
      reset('password')
    }
  }, [])

  const submit: FormEventHandler = (e) => {
    e.preventDefault()

    post(route('login'))
  }

  return (
    <GuestLayout>
      <Head title="Log in"/>
      {status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}

      <form onSubmit={submit}>
        <div>
          <InputLabel htmlFor="email" value="Email"/>

          <TextInput
            id="email"
            type="email"
            name="email"
            value={data.email}
            className="mt-1 block w-full"
            autoComplete="username"
            isFocused={true}
            onChange={(e) => setData('email', e.target.value)}
          />

          <InputError message={errors.email} className="mt-2"/>
        </div>

        <div className="mt-4">
          <InputLabel htmlFor="password" value="Jelszó"/>

          <TextInput
            id="password"
            type="password"
            name="password"
            value={data.password}
            className="mt-1 block w-full"
            autoComplete="current-password"
            onChange={(e) => setData('password', e.target.value)}
          />

          <InputError message={errors.password} className="mt-2"/>
        </div>

        <div className="block mt-4">
          <label className="flex items-center">
            <Checkbox
              name="remember"
              checked={data.remember}
              onChange={(e) => setData('remember', e.target.checked)}
            />
            <span className="ml-2 text-sm text-gray-600">Bejelentkezve maradok</span>
          </label>
        </div>

        <div className="flex items-center justify-between mt-4">
          {canResetPassword && (
            <Link
              href={route('password.request')}
              className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-700"
            >
              Elfelejtett jelszó?
            </Link>
          )}

          <PrimaryButton className="ml-4" disabled={processing}>
            Bejelentkezés
          </PrimaryButton>
        </div>
        <Divider my="sm" label={"Nincs még fiókja?"} labelPosition={"center"}/>
        <Flex justify={"center"}>
          <Link
            href={route('register')}
            className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-700"
          >
            Regisztráció
          </Link>
        </Flex>
      </form>
    </GuestLayout>
  )
}
