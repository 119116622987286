import {InputHTMLAttributes} from 'react'

export default function Checkbox({className = '', ...props}: InputHTMLAttributes<HTMLInputElement>) {
  return (
    <input
      {...props}
      type="checkbox"
      className={
        'form-checkbox border-gray-300 text-brand-600 shadow-sm focus:ring-brand-700 ' +
        className
      }
    />
  )
}
